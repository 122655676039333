import React, { useEffect, useRef } from 'react'
import "./features.scss"
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useTranslation } from 'react-i18next';

gsap.registerPlugin(ScrollTrigger);

const Features = () => {
    const textRef = useRef(null);

    useEffect(() => {
        const textElement = textRef.current;
        const textContent = textElement.textContent;
        textElement.innerHTML = textContent.split('').map(char => `<span>${char}</span>`).join('');

        const chars = textElement.querySelectorAll('span');

        gsap.fromTo(chars,
            { scale: 0.8, opacity: 0 },
            {
                scale: 1,
                opacity: 1,
                duration: 1,
                ease: 'power1.out',
                stagger: {
                    amount: 1.5,
                    from: "start"
                },
                delay: 0,
                scrollTrigger: {
                    trigger: textElement,
                    start: 'top 70%',
                    once: true,
                }
            }
        );
    }, []);
    const {t} = useTranslation();
    return (
        <>
            <div className="wrapper-withouttopside" id='features'>
                <div className="wrapper-feature">
                    <div className="wrapper-structure">
                        <div className="left-side-grid"></div>
                        <div className="mid-side-grid">
                            <section className="main-features">
                                <div className="main-heading">
                                    <h6>{t('Features-t1')}</h6>
                                    <h3 ref={textRef}>{t('Features-t2')}</h3>
                                    <p>{t('Features-t3')}</p>
                                </div>
                                <div className="bottom-cards">
                                    <div className="single-card">
                                        <h5>{t('Features-t4')}</h5>
                                        <p>{t('Features-t5')}</p>
                                        <div className="lottie-img">
                                            <img src="\assets\usecases\imgf.svg" alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className="single-card">
                                        <h5>{t('Features-t6')}</h5>
                                        <p>{t('Features-t7')}</p>
                                        <div className="lottie-img">
                                            <img src="\assets\usecases\imgf2.svg" alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className="single-card">
                                        <h5>{t('Features-t8')}</h5>
                                        <p>{t('Features-t9')}</p>
                                        <div className="lottie-img">
                                            <img src="\assets\usecases\imgf3.svg" alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className="single-card">
                                        <h5>{t('Features-t10')}</h5>
                                        <p>{t('Features-t11')}</p>
                                        <div className="lottie-img">
                                            <img src="\assets\usecases\img11.svg" alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className="single-card">
                                        <h5>{t('Features-t12')}</h5>
                                        <p>{t('Features-t13')}</p>
                                        <div className="lottie-img">
                                            <img src="\assets\usecases\img12.svg" alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className="single-card">
                                        <h5>{t('Features-t14')}</h5>
                                        <p>{t('Features-t15')}</p>
                                        <div className="lottie-img">
                                            <img src="\assets\usecases\img13.svg" alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="right-side-grid"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Features
