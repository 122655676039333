import React, { useEffect, useRef } from 'react'
import "./third.scss"
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';

// Ensure ScrollTrigger is registered
gsap.registerPlugin(ScrollTrigger);

const Third = () => {
    const textRefs = useRef([]);

  useEffect(() => {
    textRefs.current.forEach((textElement) => {
      if (textElement) {
        const textContent = textElement.textContent;
        textElement.innerHTML = textContent.split('').map(char => `<span>${char}</span>`).join('');

        const chars = textElement.querySelectorAll('span');

        gsap.fromTo(chars,
          { scale: 0.8, opacity: 0 },
          {
            scale: 1,
            opacity: 1,
            duration: 1,
            ease: 'power1.out',
            stagger: {
              amount: 0.8,
              from: "start"
            },
            delay: 0,
            scrollTrigger: {
              trigger: textElement,
              start: 'top 70%',
              once: true,
            }
          }
        );
      }
    });
  }, []);

  const {t} = useTranslation();
    return (
        <>
            <div className="wrapper-withouttopside" id='products'>
                <div className="wrapper-third">
                    <div className="wrapper-structure">
                        <div className="left-side-grid"></div>
                        <div className="mid-side-grid">
                            <section className="third-section first-section" id='section1'>
                                <div className="parent">
                                    <div className="left-side">
                                        <div className="main-content">
                                            <h6>{t('Third-t1')}</h6>
                                            <div className="inside-content">
                                                <h4 ref={el => textRefs.current[0] = el}>{t('Third-t2')}</h4>
                                                <p>{t('Third-t3')}</p>
                                                <p>{t('Third-t4')} <a href="https://tokentimes.ai/" target='_blank' style={{color: '#fff'}}>{t('Third-t5')}</a>, {t('Third-t6')}</p>
                                            </div>
                                            {/* <div class="btn-container">
                                                <button className="btn-blue">
                                                    <span class="text">Explore</span>
                                                    <div class="icon-container">
                                                        <div class="icon icon--left">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1984 9.27765L3 9.27765L3 7.72216L10.1984 7.72216L10.1984 5L15 8.5L10.1984 12L10.1984 9.27765Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                        <div class="icon icon--right">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1984 9.27765L3 9.27765L3 7.72216L10.1984 7.72216L10.1984 5L15 8.5L10.1984 12L10.1984 9.27765Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="right-side">
                                        <div className="wrapper-img first">
                                            <img src="\assets\third\main-img1.png" alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="third-section sec-section" id='section2'>
                                <div className="parent">
                                    <div className="left-side">
                                        <div className="main-content">
                                            <h6>{t('Third-t7')}</h6>
                                            <div className="inside-content">
                                                <h4 ref={el => textRefs.current[1] = el}>{t('Third-t8')}</h4>
                                                <p>{t('Third-t9')}</p>
                                            </div>
                                            {/* <div class="btn-container">
                                                <button className="btn-blue">
                                                    <span class="text">Explore</span>
                                                    <div class="icon-container">
                                                        <div class="icon icon--left">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1984 9.27765L3 9.27765L3 7.72216L10.1984 7.72216L10.1984 5L15 8.5L10.1984 12L10.1984 9.27765Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                        <div class="icon icon--right">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1984 9.27765L3 9.27765L3 7.72216L10.1984 7.72216L10.1984 5L15 8.5L10.1984 12L10.1984 9.27765Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="right-side">
                                        <div className="wrapper-img sec">
                                            <img src="\assets\third\main-img2.png" alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="third-section thi-section" id='section3'>
                                <div className="parent">
                                    <div className="left-side">
                                        <div className="main-content">
                                            <h6>{t('Third-t10')}</h6>
                                            <div className="inside-content">
                                                <h4 ref={el => textRefs.current[2] = el}>{t('Third-t11')}</h4>
                                                <p>{t('Third-t12')}</p>
                                            </div>
                                            {/* <div class="btn-container">
                                                <button className="btn-blue">
                                                    <span class="text">Explore</span>
                                                    <div class="icon-container">
                                                        <div class="icon icon--left">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1984 9.27765L3 9.27765L3 7.72216L10.1984 7.72216L10.1984 5L15 8.5L10.1984 12L10.1984 9.27765Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                        <div class="icon icon--right">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1984 9.27765L3 9.27765L3 7.72216L10.1984 7.72216L10.1984 5L15 8.5L10.1984 12L10.1984 9.27765Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="right-side">
                                        <div className="wrapper-img third">
                                            <img src="\assets\third\main-img3.png" alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="third-section fourth-section" id='section4'>
                                <div className="parent">
                                    <div className="left-side">
                                        <div className="main-content">
                                            <h6>{t('Third-t13')}</h6>
                                            <div className="inside-content">
                                                <h4 ref={el => textRefs.current[3] = el}>{t('Third-t14')}</h4>
                                                <p>{t('Third-t15')}</p>
                                            </div>
                                            {/* <div class="btn-container">
                                                <button className="btn-blue">
                                                    <span class="text">Explore</span>
                                                    <div class="icon-container">
                                                        <div class="icon icon--left">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1984 9.27765L3 9.27765L3 7.72216L10.1984 7.72216L10.1984 5L15 8.5L10.1984 12L10.1984 9.27765Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                        <div class="icon icon--right">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1984 9.27765L3 9.27765L3 7.72216L10.1984 7.72216L10.1984 5L15 8.5L10.1984 12L10.1984 9.27765Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="right-side">
                                        <div className="wrapper-img fifth">
                                            <img src="\assets\third\main-img5.png" alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="third-section fifth-section" id='section5'>
                                <div className="parent">
                                    <div className="left-side">
                                        <div className="main-content">
                                            <h6>{t('Third-t16')}</h6>
                                            <div className="inside-content">
                                                <h4 ref={el => textRefs.current[4] = el}>{t('Third-t17')}</h4>
                                                <p>{t('Third-t18')}</p>
                                            </div>
                                            {/* <div class="btn-container">
                                                <button className="btn-blue">
                                                    <span class="text">Explore</span>
                                                    <div class="icon-container">
                                                        <div class="icon icon--left">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1984 9.27765L3 9.27765L3 7.72216L10.1984 7.72216L10.1984 5L15 8.5L10.1984 12L10.1984 9.27765Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                        <div class="icon icon--right">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1984 9.27765L3 9.27765L3 7.72216L10.1984 7.72216L10.1984 5L15 8.5L10.1984 12L10.1984 9.27765Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="right-side">
                                        <div className="wrapper-img fourth">
                                            <img src="\assets\third\main-img4.png" alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                        <div className="right-side-grid"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Third
