import React, { useEffect, useRef } from 'react'
import "./integration.scss"

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useTranslation } from 'react-i18next';

gsap.registerPlugin(ScrollTrigger);

const Integration = () => {
    const textRef = useRef(null);

  useEffect(() => {
    const textElement = textRef.current;
    const textContent = textElement.textContent;
    textElement.innerHTML = textContent.split('').map(char => `<span>${char}</span>`).join('');

    const chars = textElement.querySelectorAll('span');

    gsap.fromTo(chars,
      { scale: 0.8, opacity: 0 },
      {
        scale: 1,
        opacity: 1,
        duration: 1,
        ease: 'power1.out',
        stagger: {
          amount: 1,
          from: "start"
        },
        delay: 0, 
        scrollTrigger: {
          trigger: textElement,
          start: 'top 70%',
          once: true, 
        }
      }
    );
  }, []);

  const {t} = useTranslation();
    return (
        <>
            <div className="wrapper-withouttopside">
                <div className="wrapper-integration">
                    <div className="wrapper-structure">
                        <div className="left-side-grid"></div>
                        <div className="mid-side-grid">
                            <section className="main-integration">
                                <div className="main-heading">
                                    <h6>{t('Integration-t1')}</h6>
                                    <h3 ref={textRef}>{t('Integration-t2')}</h3>
                                </div>
                                <div className="bottom-logos">
                                    <a href="#"><img src="\assets\integration\nvidea.png" alt="img" className='img-fluid img1' /></a>
                                    <a href="#"><img src="\assets\integration\openai.png" alt="img" className='img-fluid img2' /></a>
                                    <a href="#"><img src="\assets\integration\anth.png" alt="img" className='img-fluid img3' /></a>
                                    <a href="#"><img src="\assets\integration\meta.png" alt="img" className='img-fluid img4' /></a>
                                    <a href="#"><img src="\assets\integration\flux.png" alt="img" className='img-fluid img5' /></a>
                                    <a href="#"><img src="\assets\integration\mid.png" alt="img" className='img-fluid img6' /></a>
                                    <a href="#"><img src="\assets\integration\a1.png" alt="img" className='img-fluid img7' /></a>
                                    <a href="#"><img src="\assets\integration\incentiv.png" alt="img" className='img-fluid img8' /></a>
                                </div>
                            </section>
                        </div>
                        <div className="right-side-grid"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Integration
