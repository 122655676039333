import '../../app.scss';
import React, { useEffect } from "react";
import Banner from './main-banner/Banner.js';
import Features from './Features/Features.js';
import Third from './Third/Third.js';
import Usecases from './UseCases/Usecases.js';
import Omp from './Omp/Omp.js';
import Faster from './Faster/Faster.js';
import Footer from './footer/Footer.js';
import Integration from './Integration/Integration.js';






function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
          <Banner />
          <Integration />
          <Features />
          <Third />
          <Usecases />
          <Omp />
          <Faster />
          <Footer />
    </>
  );
}

export default Landing;