import React, { useEffect, useRef, useState } from 'react'
import "./usecases.scss"
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';

// Ensure ScrollTrigger is registered
gsap.registerPlugin(ScrollTrigger);

const Usecases = () => {
    const baseWidth = 1600; 
    const cardWidthPx = 277; 
    const gapPx = 10; 
  
   
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); 
  
    
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
  
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize); 
      };
    }, []);
  
    
    const cardWidthDesktop = (cardWidthPx / baseWidth) * 100;
    const gapDesktop = (gapPx / baseWidth) * 100; 
  
    
    const cardWidth = isMobile ? cardWidthPx : cardWidthDesktop;
    const gap = isMobile ? gapPx : gapDesktop; 
  
    
    const scrollRef = useRef(null);
    const [isPrevDisabled, setIsPrevDisabled] = useState(true);
    const [isNextDisabled, setIsNextDisabled] = useState(false);
  
    const cardsPerClick = 1; 
  
    const updateButtonState = () => {
      if (scrollRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
        setIsPrevDisabled(scrollLeft === 0);
        setIsNextDisabled(scrollLeft + clientWidth >= scrollWidth);
      }
    };
  
    const handleNext = () => {
      if (scrollRef.current) {
       
        scrollRef.current.scrollLeft += cardsPerClick * (isMobile ? cardWidth + gap : (window.innerWidth * cardWidth) / 100 + (window.innerWidth * gap) / 100);
        setTimeout(updateButtonState, 300); 
      }
    };
  
    const handlePrev = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollLeft -= cardsPerClick * (isMobile ? cardWidth + gap : (window.innerWidth * cardWidth) / 100 + (window.innerWidth * gap) / 100);
        setTimeout(updateButtonState, 300); 
      }
    };
  
    useEffect(() => {
      updateButtonState(); 
    }, []);

    const textRef = useRef(null);

    useEffect(() => {
      const textElement = textRef.current;
      const textContent = textElement.textContent;
      textElement.innerHTML = textContent.split('').map(char => `<span>${char}</span>`).join('');
  
      const chars = textElement.querySelectorAll('span');
  
      gsap.fromTo(chars,
        { scale: 0.8, opacity: 0 },
        {
          scale: 1,
          opacity: 1,
          duration: 1,
          ease: 'power1.out',
          stagger: {
            amount: 1,
            from: "start"
          },
          delay: 0, 
          scrollTrigger: {
            trigger: textElement,
            start: 'top 70%',
            once: true, 
          }
        }
      );
    }, []);

    const [t] = useTranslation();

    return (
        <>
            <div className="wrapper-withouttopside" id='usecases'>
                <div className="wrapper-usecases">
                    <div className="wrapper-structure">
                        <div className="left-side-grid"></div>
                        <div className="mid-side-grid">
                            <section className="main-usecases">
                                <div className="main-heading">
                                    <div className="left-content">
                                        <h6>{t('Usecases-t1')}</h6>
                                        <h4 ref={textRef}>{t('Usecases-t2')}</h4>
                                    </div>
                                    <div className="twice-btn">
                                        <a onClick={handlePrev} style={isPrevDisabled ? {opacity: "0.5"} : {}} disabled={isPrevDisabled} className='btn-left'><img src="\assets\usecases\btn-left.svg" alt="img" className='img-fluid' /></a>
                                        <a onClick={handleNext} style={isNextDisabled ? {opacity: "0.5"} : {}} disabled={isNextDisabled} className='btn-right'><img src="\assets\usecases\btn-right.svg" alt="img" className='img-fluid' /></a>
                                    </div>
                                </div>
                                <div className="scroll-x-overflow" ref={scrollRef}
                                    style={{
                                        display: 'flex',
                                        overflowX: 'auto',
                                        scrollBehavior: 'smooth',
                                        // Adjust to show the exact number of cards per click
                                    }}
                                    onScroll={updateButtonState}>
                                    <div className="bottom-cards">
                                        <div className="single-card">
                                            <div className="main-img">
                                                <img src="\assets\usecases\img1.svg" alt="img" className='img-fluid' />
                                            </div>
                                            <div className="bottom-text">
                                                <h6>{t('Usecases-t3')}</h6>
                                                <p>{t('Usecases-t4')}</p>
                                            </div>
                                        </div>
                                        <div className="single-card">
                                            <div className="main-img">
                                                <img src="\assets\usecases\img2.svg" alt="img" className='img-fluid' />
                                            </div>
                                            <div className="bottom-text">
                                                <h6>{t('Usecases-t5')}</h6>
                                                <p>{t('Usecases-t6')}</p>
                                            </div>
                                        </div>
                                        <div className="single-card">
                                            <div className="main-img">
                                                <img src="\assets\usecases\img3.svg" alt="img" className='img-fluid' />
                                            </div>
                                            <div className="bottom-text">
                                                <h6>{t('Usecases-t7')}</h6>
                                                <p>{t('Usecases-t8')}</p>
                                            </div>
                                        </div>
                                        <div className="single-card">
                                            <div className="main-img">
                                                <img src="\assets\usecases\img4.svg" alt="img" className='img-fluid' />
                                            </div>
                                            <div className="bottom-text">
                                                <h6>{t('Usecases-t9')}</h6>
                                                <p>{t('Usecases-t10')}</p>
                                            </div>
                                        </div>
                                        <div className="single-card">
                                            <div className="main-img">
                                                <img src="\assets\usecases\img5.svg" alt="img" className='img-fluid' />
                                            </div>
                                            <div className="bottom-text">
                                                <h6>{t('Usecases-t11')}</h6>
                                                <p>{t('Usecases-t12')}</p>
                                            </div>
                                        </div>
                                        <div className="single-card">
                                            <div className="main-img">
                                                <img src="\assets\usecases\img6.svg" alt="img" className='img-fluid' />
                                            </div>
                                            <div className="bottom-text">
                                                <h6>{t('Usecases-t13')}</h6>
                                                <p>{t('Usecases-t14')}</p>
                                            </div>
                                        </div>
                                        <div className="single-card">
                                            <div className="main-img">
                                                <img src="\assets\usecases\img7.svg" alt="img" className='img-fluid' />
                                            </div>
                                            <div className="bottom-text">
                                                <h6>{t('Usecases-t15')}</h6>
                                                <p>{t('Usecases-t16')}</p>
                                            </div>
                                        </div>
                                        <div className="single-card">
                                            <div className="main-img">
                                                <img src="\assets\usecases\img8.svg" alt="img" className='img-fluid' />
                                            </div>
                                            <div className="bottom-text">
                                                <h6>{t('Usecases-t17')}</h6>
                                                <p>{t('Usecases-t18')}</p>
                                            </div>
                                        </div>
                                        <div className="single-card">
                                            <div className="main-img">
                                                <img src="\assets\usecases\img9.svg" alt="img" className='img-fluid' />
                                            </div>
                                            <div className="bottom-text">
                                                <h6>{t('Usecases-t19')}</h6>
                                                <p>{t('Usecases-t20')}</p>
                                            </div>
                                        </div>
                                        <div className="single-card">
                                            <div className="main-img">
                                                <img src="\assets\usecases\img10.svg" alt="img" className='img-fluid' />
                                            </div>
                                            <div className="bottom-text">
                                                <h6>{t('Usecases-t21')}</h6>
                                                <p>{t('Usecases-t22')}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="right-side-grid"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Usecases
